<template>
  <div>
    <div
      id="main-content"
      :class="`${
        collapse && !isMobile ? 'i-is-collapsed s-ml-300 s-p-20' : 's-p-20'
      }`"
    >
      <div
        class="s-flex"
        v-if="backButton"
        @click="backToPage()"
        style="cursor: pointer"
      >
        <img :src="require(`@/assets/icons/arrow-ios-back-black.svg`)" />
        <span style="align-items: center" class="s-mt-2">{{$t('general.back')}}</span>
      </div>
      <div class="s-flex">
        <h3 class="i-name-dashboard s-mr-auto">{{ menuName }}</h3>
        <Button
          v-if="dashboard && role_type == 'public'"
          class="i-button s-ml-auto s-mt-10"
          @click="moveToExpertForm()"
          :disabled="user.status == 'Pengajuan Expert'"
        >
          {{
            user.status == "Pengajuan Expert"
              ? $t('general.waitingForApproval')
              : $t('general.requestExpertRole')
          }}
        </Button>
      </div>

      <div class="s-flex">
        <Button
          v-if="newButton"
          class="i-button s-mr-auto"
          @click="moveToForm()"
        >
          {{$t('general.addNew')}}
        </Button>
      </div>

      <slot name="dashboard"></slot>

      <slot name="article"> </slot>

      <div :class="`${isMobile ? '' : 's-mlr-60'}`">
        <slot name="form"> </slot>
      </div>

      <slot name="detail"> </slot>

      <slot name="comment"> </slot>

      <slot name="table-header"> </slot>

      <slot name="table"> </slot>

      <div class="i-floating-back">
        <div class="i-circle s-mr-auto s-flex" @click="backToTop()">
          <img
            :src="require(`@/assets/icons/arrow-ios-upward.svg`)"
            style="justify-content: center; align-items: center"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import sidebar from "./sidebar.vue";
import form from "@/mixins/form";
import { mapActions, mapGetters } from "vuex";

export default {
  // eslint-disable-next-line vue/no-unused-components
  components: { sidebar },
  mixins: [form],
  props: {
    menuName: String,
    columns: Array,
    rows: Array,
    table: Boolean, //sementara, karena input field di slot table kok muncul disemuanya
    newButton: Boolean,
    dashboard: Boolean,
    backButton: Boolean,
  },
  data() {
    return {
      acceptedExpert: false,
      // nama_user: "King Arkin",
      // role_user: "Admin",
      //Table
      open_dropdown: false,
      searchTable: "",
      selected_row: [],
    };
  },
  computed: {
    ...mapGetters({
      role_type: "auth/getRole",
      user: "auth/getUser",
      collapse: 'getCollapse',
      isMobile: 'getIsMobile',
      isModal: 'getModal'
    }),
  },
  async mounted() {
    this.$store.dispatch("setIsMobile", window.innerWidth); //mounted pertama kali, baca apakah ukuran mobile/tidak
  },
  methods: {
    ...mapActions({
      setModal: "setModal",
      setCollapse: "setCollapse",

    }),
    openDropdown(row, table_index) {
      const position = (70 * (table_index + 1)).toString();
      // eslint-disable-next-line no-unused-vars
      const dropdown = (document.getElementById("Dropdown-Content").style.top =
        position.toString() + "px");
      this.selected_row = row;
      row.open_dropdown = row.open_dropdown ? false : true;
    },
    openExpertContact() {
      this.setModal(true);
    },
    moveToForm() {
      this.$emit("moveToForm");
    },
    moveToUserForm() {
      this.$emit("moveToUserForm");
    },
    moveToExpertForm() {
      this.$router.push(this.$translate({
        name: "New Expert Request",
      }));
    },
    backToPage() {
      this.$emit("backToPage");
    },
    backToTop() {
      window.scroll(0, 0);
    },
  },
};
</script>
