<template>
  <div>
    <div style="position: relative">
      <span>
        <button @click="changeCollapseState()" class="sd-btn-back">
          <img :src="require(`@/assets/icons/arrow-ios-forward.svg`)" />
        </button>
      </span>

      <div
        id="Sidebar"
        :class="`${isMobile ? 's-fullWidth' : ''} ${
          collapse ? '' : 'hide-sidebar'
        }`"
        style="max-width: 320px"
      >
        <div>
          <button @click="changeCollapseState()" class="sd-btn-close">
            <img :src="require(`@/assets/icons/close.svg`)" />
          </button>
        </div>
        <div class="s-flex s-m-16">
          <div class="s-position-relative s-ml-auto">
            <div
              class="s-lang-container s-wd-100 s-center-flex s-bg-white"
              @click.prevent="openDrop = !openDrop"
            >
              <input
                readonly
                v-model="currentLang"
                class="s-cursor-pointer s-wd-56"
              />
              <div class="s-square-16">
                <img
                  class="s-fullWidth s-cursor-pointer"
                  :src="require('@/assets/icons/primary/icon-arrow-down.svg')"
                />
              </div>
            </div>
            <div
              v-if="openDrop"
              class="s-position-absolute s-card s-wd-80 s-p-5 s-bg-white"
              style="z-index: 100"
            >
              <div
                class="s-card-item s-cursor-pointer"
                @click.prevent="changeLang('id')"
                style="font-size: 12px"
              >
                ID
              </div>
              <div
                class="s-card-item s-cursor-pointer"
                @click.prevent="changeLang('en')"
                style="font-size: 12px"
              >
                EN
              </div>
            </div>
          </div>
        </div>
        <!-- HEADER -->
        <div class="sd-header s-flex">
          <div class="i-image-container">
            <div class="i-image-cropper">
              <img
                :src="user.profile_url == null ? require('@/assets/image-profile.png') : user.profile_url"
                alt="Avatar"
                class="i-image-profile"
              />
            </div>
            <div class="i-name-role">
              <h5
                class="s-text-white s-mt-10"
                style="
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  overflow: hidden;
                  max-width: 150px;
                "
              >
                {{ user.name }}
              </h5>
              <span
                class="i-role s-text-white s-mt--20"
                style="font-size: 14px"
              >
                {{ user.role_name }}
              </span>
            </div>
          </div>
        </div>

        <!-- MENU -->
        <div class="sd-body" style="margin-top: -16px; overflow: scroll">
          <div class="sd-body-item">
            <div v-for="(m, i) in main_items" :key="`item-${i}`">
              <div
                @click="changeMenu(i, m)"
                class="s-center-flex sd-body-item-select"
              >
                <div
                  :class="`sd-item ${m.sd_item_selected ? 'sd-selected' : ''}`"
                >
                  <img
                    class="iconSidebar"
                    :src="require(`@/assets/icons/${m.url_icon}`)"
                  />
                  <p>{{ $t(m.text) }}</p>

                  <img
                    v-if="m.hasOwnProperty('children')"
                    :src="
                      require(`@/assets/icons/arrow-ios-${
                        m.sd_item_selected ? 'upward' : 'downward'
                      }.svg`)
                    "
                    class="iconSidebar s-ml-auto"
                  />
                </div>
              </div>
              <div
                v-if="m.hasOwnProperty('children') && m.sd_item_selected"
                class="sd-sub-body-item-selected"
              >
                <div v-for="(sub, j) in m.children" :key="`sub-item-${j}`">
                  <div
                    @click="changeSubMenu(i, j, sub)"
                    class="s-center-flex sd-body-item-select"
                  >
                    <div
                      :class="`sd-item ${
                        sub.sub_item_selected ? 'sd-selected' : ''
                      }`"
                    >
                      <p>{{ $t(sub.name) }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- LOGOUT -->
          <div
            :class="`sd-body-item s-bg-primary ${
              collapse ? '' : 'hide-sidebar'
            }`"
            style="position: fixed; bottom: 0"
            @click.prevent="logout()"
          >
            <div class="s-center-flex sd-body-item-select">
              <div class="sd-item">
                <img
                  class="iconSidebar"
                  :src="require(`@/assets/icons/logout.svg`)"
                />
                <p>Logout</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { Trans } from "@/plugins/translation";
export default {
  props: {
    nama_user: String,
    role_user: String,
    main_items: Array,
  },
  data: () => ({
    sidebar: false,
    id_selected_item: null,
    id_selected_sub_item: null,
    openDrop: false,
    dp_body_item: false,
  }),
  methods: {
    ...mapActions({
      setUserData: "auth/setUserData",
      logoutStorage: "logoutStorage",
      logoutUser: "auth/logoutUser",
      setLocale: "locale/setCurrentLocale",
      setCollapse: "setCollapse",
    }),
    async initData() {
      await this.setUserData();
    },
    async switchLocale() {
      await Trans.changeLocale(this.lang);
      let route = {
        ...this.$route,
      };
      route.params = {
        ...route.params,
        locale: this.lang == "en" ? "en" : null,
      };
      this.$router.push(route).catch((error) => {
        if (error.name != "NavigationDuplicated") {
          throw error;
        }
      });
    },

    pushRoute(name) {
      this.$router.push(this.$translate({ name: name }));
    },
    changeLang(lang) {
      this.setLocale(lang);
      this.openDrop = false;
      this.switchLocale();
    },

    changeMenu(index, m) {
      if (this.id_selected_item != null) {
        if (this.id_selected_item != index) {
          this.main_items[this.id_selected_item].sd_item_selected = false; //menu lama yg terselect di unselect
          this.main_items[index].sd_item_selected = true; //menu baru yang terselect
          console.log("masuk awal");

          //Unselect apabila ada children
          if (
            this.main_items[this.id_selected_item].hasOwnProperty("children")
          ) {
            console.log("masuk if pertama");
            if (this.id_selected_sub_item != null) {
              this.main_items[this.id_selected_item]["children"][
                this.id_selected_sub_item
              ]["sub_item_selected"] = false; //menu lama yg terselect di unselect
            }
          }

          if (!this.main_items[index].hasOwnProperty("children")) {
            if (this.isMobile) this.changeCollapseState();
          }
        }
        //Apabila klik menu yang sudah selected
        else {
          console.log("masuk sini");
          if (
            this.main_items[this.id_selected_item].hasOwnProperty("children")
          ) {
            if (this.main_items[this.id_selected_item].sd_item_selected)
              this.main_items[this.id_selected_item].sd_item_selected = false;
            else this.main_items[this.id_selected_item].sd_item_selected = true;
          }
        }
        if (m.hasOwnProperty("to"))
          this.$router.push(this.$translate({ name: m.to, params: m.params }));
      } else {
        this.main_items[index].sd_item_selected = true; //pertama kali pilih menu
        if (m.hasOwnProperty("to"))
          this.$router.push(this.$translate({ name: m.to, params: m.params }));

        if (!this.main_items[index].hasOwnProperty("children")) {
          if (this.isMobile) this.changeCollapseState();
        }
      }

      this.id_selected_item = index;
    },

    changeSubMenu(i, j, sub) {
      if (this.id_selected_item != null) {
        if (this.id_selected_sub_item != null) {
          if (this.id_selected_sub_item != j) {
            // eslint-disable-next-line no-prototype-builtins
            if (this.main_items[i].hasOwnProperty("children")) {
              this.main_items[this.id_selected_item]["children"][
                this.id_selected_sub_item
              ]["sub_item_selected"] = false; //menu lama yg terselect di unselect
              this.main_items[i]["children"][j]["sub_item_selected"] = true; //menu baru yang terselect
            }
          }

          this.$router.push(
            this.$translate({ name: sub.to, params: sub.params })
          );
        } else {
          this.main_items[i]["children"][j]["sub_item_selected"] = true; //pertama kali pilih menu
          this.$router.push(
            this.$translate({ name: sub.to, params: sub.params })
          );
        }
      }
      this.id_selected_sub_item = j;
      if (this.isMobile) this.changeCollapseState();
    },

    // setCollapse() {
    //   this.$store.commit("SET_COLLAPSE");
    // },

    handleResize() {
      this.$store.dispatch("setIsMobile", window.innerWidth);
    },
    changeCollapseState() {
      if (this.collapse) this.setCollapse(false);
      else this.setCollapse(true);
    },

    async logout() {
      await this.logoutUser();
      await this.logoutStorage();
      this.$router.push(
        this.$translate({
          path: "/login",
        })
      );
    },
  },
  mounted() {
    this.initData();
    this.setCollapse(true);
  },
  unmounted() {
    window.removeEventListener("resize", this.handleResize);
  },
  created() {
    window.addEventListener("resize", this.handleResize);
  },
  computed: {
    ...mapGetters({
      role_type: "auth/getRole",
      user: "auth/getUser",
      lang: "locale/getCurrentLocale",
    }),
    currentLang() {
      if (this.lang == "id") return "Indonesia";
      else return "English";
    },
    collapse() {
      return this.$store.getters["getCollapse"];
    },
    isMobile() {
      return this.$store.getters["getIsMobile"];
    },
  },
};
</script>
