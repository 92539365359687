<template>
  <div>
    <content-template
      :menuName="item.title"
      :backButton="true"
      @backToPage="moveToOther('BRRD')"
    >
      <template #article>
        <div class="s-mb-20 s-flex">
          <div class="s-ml-auto" v-if="item.status == 'Menunggu verifikasi'">
            <Button
              color="error"
              @click.prevent="onVerifyAction('reject', item.id)"
              >{{ $t("general.reject") }}</Button
            >
            <Button
              class="s-ml-20"
              @click.prevent="onVerifyAction('accept', item.id)"
              >{{ $t("general.accept") }}</Button
            >
          </div>
        </div>
        <img
          :src="item.thumbnail"
          :alt="item.title"
          class="i-detail-image i-shadow"
        />
        <p style="margin: 20px 0px 30px 0px">
          <span style="float: left">
            <b>{{ convertDate(item.date) }}</b>
          </span>
          <span style="float: right">
            <b>{{ item.creator_name }}</b>
          </span>
        </p>
        <br />
        <hr />

        <div class="i-square s-text-white">
          <h5 class="s-mtb-5 s-text-white">{{ $t("general.description") }}</h5>
          <span class="s-text-white" style="text-align: justify">{{
            item.description
          }}</span>
        </div>

        <div class="i-square">
          <h5 class="s-mtb-5 s-text-white">
            {{ $t("general.topics") }}
          </h5>
          <span>
            <span
              class="s-text-white"
              v-for="(e, i) in item.expertise_detail"
              :key="`expertise-${i}`"
            >
              {{ e.name }}
              <span
                class="s-text-white"
                v-if="i < item.expertise_detail.length - 1"
                >|
              </span>
            </span>
          </span>
        </div>

        <div
          class="i-square"
          v-if="item.youtube_link != null && item.youtube_link != ''"
        >
          <h5 class="s-mtb-5 s-text-white">Video</h5>
          <iframe
            v-if="item.youtube_link != null && item.youtube_link != ''"
            :src="`https://www.youtube.com/embed/${item.youtube_link}`"
            style="display: block"
            :class="`s-ml-auto s-mr-auto ${
              !isMobile ? 's-wd-70-p s-ht-500' : 's-fullWidth s-ht-300'
            }`"
          />
        </div>
      </template>
    </content-template>
  </div>
</template>

<script>
import ContentTemplate from "./contentTemplate.vue";
import form from "@/mixins/form";
import { mapActions, mapGetters } from "vuex";
import { convertDate } from "@/common/timeformat";

export default {
  components: { ContentTemplate },
  mixins: [form],
  data() {
    return {};
  },
  mounted() {
    this.initData();
  },

  computed: {
    ...mapGetters({
      item: "content/getContent",
    }),
    isMobile() {
      return this.$store.getters["getIsMobile"];
    },
  },

  methods: {
    ...mapActions({
      onVerify: "content/onVerify",
      setContent: "content/setContentSingleData",
    }),

    async onVerifyAction(action, id) {
      const data = {
        id,
        action,
      };
      await this.onVerify(data);
      if (this.$store.getters["content/getStatus"] == 1) {
        this.$router.push(this.$translate({ name: "BRRD" }));
      }
    },

    async initData() {
      this.setLoadingPage(true);
      await this.setContent(this.$route.params.slug);
      this.setLoadingPage(false);
    },

    convertDate(value) {
      return convertDate(value);
    },
  },
};
</script>

